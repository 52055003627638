export default function CleverCoreHead() {
  return (
    <>
      <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
      <script
        data-cfasync="false"
        type="text/javascript"
        id="clever-core"
        dangerouslySetInnerHTML={{
          __html: `
            (function (document, window) {
                var a, c = document.createElement("script"), f = window.frameElement;
                c.id = "CleverCoreLoader87959";
                c.src = "https://scripts.cleverwebserver.com/8531fd1b473686e053f432d069a7d104.js";
                c.async = !0;
                c.type = "text/javascript";
                c.setAttribute("data-target", window.name || (f && f.getAttribute("id")));
                c.setAttribute("data-callback", "put-your-callback-function-here");
                c.setAttribute("data-callback-url-click", "put-your-click-macro-here");
                c.setAttribute("data-callback-url-view", "put-your-view-macro-here");
                try {
                    a = parent.document.getElementsByTagName("script")[0] || document.getElementsByTagName("script")[0];
                } catch (e) {
                    a = !1;
                }
                a || (a = document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]);
                a.parentNode.insertBefore(c, a);
            })(document, window);
            `
        }}
      />
    </>
  )
}
